.navBar__scrollArea {
  overflow-y: auto;
  overflow-x: hidden;
  //height: 100vh;
  //max-height: calc(100vh - 170px);
  // @include media-breakpoint-up(lg) {
  //   max-height: calc(100vh - 80px);
  //   &.scrolled {
  //     max-height: calc(100vh - 56px);
  //   }
  // }
}

.nav__bar__social {
  position: absolute;
  width: 100%;
  bottom: 1%;
  .nav__bar__social__inner{
    padding-left: 2rem;
    padding-right: 1rem;
    .result__step__social__container {
      width: 40px;
      height: 40px;
    }
  }
  a{
    display: inline-block;
  }
}