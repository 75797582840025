$browser-context: 18 !default;

@function rem($pixels, $context: $browser-context) {
  @if (unitless($pixels)) {
    $pixels: $pixels * 1px;
  }

  @if (unitless($context)) {
    $context: $context * 1px;
  }

  @return $pixels / $context * 1rem;
}

@mixin centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@mixin bg-img($size: cover, $position: center center, $repeat: no-repeat) {
  background: {
    position: $position;
    repeat: $repeat;
    size: $size;
  }
}

@mixin img-ratio($ratio: 56.25%) {
  height: 0;
  padding-top: $ratio;
}

@mixin animate($duration: 3s, $delay: 0s, $timing: ease,$iteration: 1, $dir: normal,  $fill: forwards) {
  animation-duration: $duration;
  animation-delay: $delay;
  animation-timing-function: $timing;
  animation-iteration-count: $iteration;
  animation-direction: $dir;
  animation-fill-mode: $fill;
  animation-play-state: running;
}

@mixin clearfix {
    &:before,
    &:after {
        content: "";
        display: table;
    }
    &:after {
        clear: both;
    }
}

@mixin no-select {
    -webkit-user-select: none; /* Chrome/Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE10+ */
    user-select: none;
}

@mixin no-drag {
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
   user-drag: none;
}

@mixin placeholder-color($color) {
  &::-webkit-input-placeholder { /* WebKit browsers */
      color: $color;
  }
  &:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
      color: $color;
  }
  &::-moz-placeholder { /* Mozilla Firefox 19+ */
      color: $color;
  }
  &:-ms-input-placeholder { /* Internet Explorer 10+ */
      color: $color;
  }
}