
.cmsPage {
  &--BlogPost {
    .contentPanel:not(.blogCommonBottom):not(.bottomPanel) .htmlPanel {
      text-align: left;
      .empty-line {
        height: 0.5rem;
      }
  
      //max-width: 700px;
      h2 {
        font-size: 2rem;
      }

      @include media-breakpoint-up(lg) {

        h2 {
          font-size: 2.6rem;
        }
      
      }
      // img {
      //   width: 100%;
      // }
    }

    .blogCommonBottom {

    }
    
    .bottomPanel .htmlPanel {
      text-align: center;
    }

    .instagram-media {
      max-width: 720px !important;
    }
    .element-invisible {
      display:none;
    }
  }
}