.ytEmdPopup {
  &__poster {
    position: relative;
    background-size: cover;
    background-position: center;
    width: 100%;
    cursor: pointer;

    &::before {
      content: '';
      width: 100%;
      height: 0;
      padding-bottom: 56.25%;
      display: block;
    }

    &::after {
      content: '';
      width: 100%;
      height: 100%;
      display: block;
      position: absolute;
      z-index: 1;
      background: rgba(#000, 0.1);
      top: 0;
      left: 0;
      transition: all 0.5s;
    }

    &:hover,
    &:focus {
      &::after {
        background: rgba(#000, 0.4);
      }

      .ytEmdPopup__playIcon {
        transform: translate(-50%, -50%) scale(1.05);
      }
    }
  }

  &__playIcon {
    color: white;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(1);
    font-size: 4rem;
    z-index: 2;
    transition: all 0.5s;
  }
}

.videoItem {
  text-align: left;
  margin: 1.5rem 0;

  h4 {
    margin-top: 1rem;
  }
}

.videoHolder {
  max-width: 1000px;
  margin: auto;
}