$themes: (
    theme1 : ($color-yellow, $color-blue, $color-light-yellow, $color-light-blue),
    theme2 : ($color-pink, $color-orange, $color-light-pink, $color-light-orange),
    theme3 : ($color-yellow, $color-purple, $color-light-yellow, $color-light-purple),
    theme4 : ($color-blue, $color-green, $color-light-blue, $color-light-green),
    theme5 : ($color-orange, $color-yellow, $color-light-orange, $color-light-yellow),
    theme6 : ($color-blue, $color-yellow, $color-light-blue, $color-light-yellow)
);

@each $theme-code,
$params in $themes {
    $color: nth($params, 1);
    $color2: nth($params, 2);
    $color3: nth($params, 3);
    $color4: nth($params, 4);

    div.body-content.theme-color-#{$theme-code} {
        .pageTiles__bsyc {
            .pageTile__bsyc {

                .pageTile__bsyc__content__details {
                    background-color: $color;
                }

                &:nth-of-type(even) {
                    .pageTile__bsyc__content__details {
                        background-color: $color2;
                    }

                    .pageTile__bsyc__content:hover {
                        .pageTile__bsyc__content__details {
                            background-color: $color4;
                        }
                    }
                }

                .pageTile__bsyc__content:hover {
                    .pageTile__bsyc__content__details {
                        background-color: $color3;
                    }
                }

            }
        }
    }
}