@import 'standard';
@import 'small';
@import 'thin';

.bannerSize, .bannerSize--standard {
  @extend %bannerSize--standard;
}

.bannerWrapperSize--thin .bannerSize, .bannerSize--thin {
  @extend %bannerSize--thin;
}

.bannerWrapperSize--small .bannerSize, .bannerSize--small {
  @extend %bannerSize--small;
}

