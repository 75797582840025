@import "../../../_scss/modules/bannerSize/full.scss";
@import "../../../_scss/modules/bannerSize/standard-video.scss";
.youtubePlayer {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  >* {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
  }
}

.youtubePlayer__iframe {
  z-index: 1;
  max-width: none;
  transition: opacity 1.5s;
  opacity: 0;
}

.youtubePlayer__poster {
  @include bg-img();
  z-index: 2;
  transition: opacity 1.5s;
  opacity: 1;
}

.youtubePlayer__mask {
  //background-color: rgba(#000, 0.2);
  z-index: 3;
}

.youtubePlayer__soundIcon {
  z-index: 10;
  position: absolute;
  right: 50px;
  bottom: 12%;
  top: auto;
  left: auto;
  width: 60px;
  height: 60px;
  color: white;
  border: white solid 2px;
  padding: 10px;
  border-radius: 50%;
  transition: all 0.7 ease;
  display: flex;
  cursor: pointer;
  svg {
    width: 100%;
    height: 100%;
  }

  &:hover {
    color: $primary-color;
    border-color: $primary-color;
  }
}


.youtubePlayer__playIcon {
  z-index: 10;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 60px;
  height: 60px;
  color: white;
  transition: all 0.7 ease;
  cursor: pointer;
  svg {
    width: 100%;
    height: 100%;
  }

  &:hover {
    color: $primary-color;
    border-color: $primary-color;
  }
}


.youtubePlayer--canPlay.youtubePlayer--loaded {
  .youtubePlayer__iframe {
    opacity: 1;
  }

  .youtubePlayer__poster {
    opacity: 0;
  }
}

.youtubePlayer--full {
  @extend %bannerSize--full;
  @include media-breakpoint-up(xl) {
    // 1200px and up
    height: calc(100vh - 2rem);
  }

}

.youtubePlayer--standard {
  @extend %bannerVideoSize--standard;
  
}

.modal-video-close-btn {
  right: 0;
}