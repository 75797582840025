.sectionLoading {
  min-height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;

  transition: all 0.3s;
  transition-delay: 0.5s;

  &.loaded {
    opacity: 1;
  }
}