.searchBar {
  margin-top: 70px;
  padding: 15px;
  padding-left: 2rem;
  padding-right: 1rem;
}

.searchBar__input__container {
  position: relative;
  &:after {
    content: 'Enter';
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 0px;
    color: black;
  }
}

.searchBar__input {
  @include placeholder-color(#000);
  //background-color: #999;
  width: 100%;
  height: 2.2rem;
  line-height: 2.2rem;
  font-size: 1rem;
  padding: 0.2rem 0.5rem;
  padding-left: 0px;
  padding-right: 0px;
  border: none;
  border-bottom: 2px solid black;

  &::placeholder{
    color: #ebebeb;
  }

  //border: 1px solid #999;
  //border-radius: 0.25rem;
}