@import './mixins';
@import './variables';

// plugins
@import './bootstrap/bootstrap';
@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";
//@import '~react-day-picker/lib/style.css';
@import '~react-modal-video/scss/modal-video.scss';

@import './fonts';
@import './base';
@import './sections';
@import './form';

// top
//@import './layout/hamburger';
//@import './layout/top';
@import './layout/top-navbar';

// bottom
@import './layout/bottomPanel';
@import './layout/bottomMenu';

@import './cms/cms-site';

// modules
@import './modules/breadcrumb';
@import './modules/rotatingBanner';
@import './modules/bannerSize';
@import './modules/pageTiles';
//@import './modules/blogTiles';
@import './modules/slider';
@import './modules/multiColumnPanel';

// components
@import '../components';

@import './site/index';

//aos
@import "~aos/src/sass/aos";

//Theme
@import './theme/theme';

@media print {
  @import './print';
}

//banner-text-bar
section.banner-text-bar{
  .htmlPanel{
    p{
      margin-bottom: 0px;
      text-align: center;
    }
  }
}

.choose-file-container{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  label.choose-file-label{
    background-color: white;
    margin-bottom: 0px !important;
    //font-family: $family-button;
    padding: 0.3rem 0.5rem;
    border-radius: 0.25rem;
    font-weight: 600;
    //color: #444;
  }
  div.file__attach__text{
    margin-left: 1rem;
    //color: white;
  }
}

body:not(.cmsedit) {
  div.cms_item.desktop-ig-feed {
    display: none;

    @include media-breakpoint-up(sm) {
      display: block;
    }
  }

  div.cms_item.mobile-ig-feed {
    display: block;

    @include media-breakpoint-up(sm) {
      display: none;
    }
  }
}

section.common-footer{
  .htmlPanel{
    a{
      color: black;
    }
  }
  div.dumy-enter{
    @include media-breakpoint-down(xs){
      display: none;
    }
  }
}

section.common-footer .multiColumnPanel{
  max-width: none;
  background: transparent;
  .contentPanel .container{
    max-width: 550px;
    &#signupForm{
      margin-top: -0.8rem;
      padding-left: 0px;
      padding-right: 0px;
      margin-left: 0px;
      margin-right: 0px;
      div.actions {
        text-align: left !important;
      }
    }
    @include media-breakpoint-down(xs){
      padding-left: 0;
      padding-right: 0;
    }
  }
  .contactForm{
    max-width: 440px;
    margin: 0;
  }
  .form-control{
    //height: calc(1.2em + 0.75rem + 2px);
    border-radius: 0.5rem;
    background-color: white;
    font-size: 16px;
    line-height: 1.1;
  }
  .form-group{
    margin-bottom: 0rem;
  }
  .mb-2{
    margin-bottom: 0px !important;
  }
  textarea{
    height: 100px;
  }

  .actions{
    margin: 0px;
    text-align: left;
    margin-top: 0.8rem;
    @include media-breakpoint-down(xs){
      text-align: center;
    }
    button{
      border-radius: 0.5rem;
      font-weight: $weight-normal;
      font-size: 16px;
      line-height: 1.1;
    }
  }

  .col-sm-4 .htmlPanel{
    //border-left: 2px solid red;
    //border-left: 3px solid white;
    padding-left: 1.5rem;
    @include media-breakpoint-down(xs){
      margin-top: 2rem;
    }
    p img{
      width: 86%;
    }
  }

  section.contentPanel{
    background-color: transparent;
    padding-top: 0px;
    padding-bottom: 0px;
    &.right__panel{
      border-left: 3px solid white;
    }
    h1, h2, h3, h4, h5, p{
      margin-top: 0px;
      margin-bottom: 1.6rem;
    }
  }

  .bottom-info-text{
    @include media-breakpoint-down(xs){
      text-align: center;
    }
  }

}

.btn-primary{
  color: $font-color;
  background-color: $color-orange;
  border-color: $color-orange;
  &:hover {
    color: white;
    background-color: $color-orange;
    border-color: $color-orange;
  }
}