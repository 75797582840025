// continuously sliding slider
.contSlider {
  max-width: 100%;
  overflow: hidden;
  background: #999;

  .sliderWrapper {
    height: 100%;
    >* {
      display: inline-block;
    }

    img {
      pointer-events: none;
    }
  }

  
}

.contSlide {
    
}

.contSlide__img {
  max-height: 100%
}