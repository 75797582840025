// overriding bootstrap variables in _boostrap-override.scss

$family-base: "sofia-pro", sans-serif;
$family-header: "RecoletaRegular", serif;

// font-size
$font-size-desktop: 20px;
$font-size-mobile: 16px;

$weight-light: 400;
$weight-normal: $weight-light;
$weight-bold: 700;

// colors - sister colours from bootrap

$color-blue: #5F92FF;
$color-light-blue: #97b8ff;
$color-yellow: #FFDC12;
$color-light-yellow: #ffed88;
$color-pink: #FFC5C9;
$color-light-pink: #ffd9dc;
$color-orange: #FF7E14;
$color-light-orange: #ffab66;

$color-purple: #EE60DD;
$color-light-purple: #f497e9;

$color-green: #00D585;
$color-light-green: #59e4b0;

$color-red: #c20f2f;

// more colors
$color-gold: #faa222;
$color-dark-gray: #252525;
$color-dark-blue: #222d64;
$color-mid-blue: #5c6594;

// site colors
$primary-color: $color-blue;
$font-color: black;
$font-color-white: #fff;
$header-color: $primary-color;
$bg-color: #ebebeb;
$link-color: $primary-color;
$link-hover-color: darken($primary-color, 10);

// top-bar
//$top-bar-border: (mobile: 5px, desktop: 12px);
//$top-bar-height: (mobile: 1.6rem, desktop: 2rem);
$top-bar-border: (
    mobile: 5px,
    desktop: 12px
);
//$top-bar-height: (mobile: 2.7rem, small-device:2.8rem, tablet:3.5rem, desktop: 3.1rem);
$top-bar-height: (
    mobile: 44px,
    small-device:56px,
    tablet:56px,
    desktop: 56px
);

// base transition
$transition-base: all 0.3s ease-in-out;
$transition-fast: all 0.2s ease-in-out;
$transition-slow: all 0.5s ease-in-out;