.bannerPanel>* {
  display: none;

  &:nth-child(1) {
    display: block;
  }
}


.bannerPanel .slick-slider .slick-track {
  background-color: #414042;
}

.slick-next {
  right: 20px;
  z-index: 1;
}

.slick-prev {
  left: 20px;
  z-index: 1;
}

.bannerPanel {
  .slick-dots {
    line-height: 0;
    width: auto;
    bottom: 3%;
    right: 2%;
    @include media-breakpoint-up(lg){
      bottom: 2%;
    }
  }

  .slick-dots li button:before {
    font-size: 16px;
    opacity: 1;
  }
  .slick-dots li.slick-active button:before{
    color: $color-pink;
  }
}