.navBar__item--open {
  .navBar__subMenu {
    //max-height: 800px;
    //transition: all 0.9s;
  }
  .navBar__icon {
    transform: rotate(-180deg);
  }
}

.back__button.navBar__link {
  cursor: pointer;
}

a.navBar__link, .back__button.navBar__link {
  font-weight: bold;
  padding-left: 1.2rem;
  @include media-breakpoint-up(lg) {
    padding-left: 2rem;
  }
}

.navBar__icon {
  position: absolute;
  top: 0;
  right: 0;
  transition: transform 0.5s ease;
  padding: 0.5rem 0.7rem;
}