.bottomMenu {
  color: black;
  position: fixed;
  z-index: 100;
  bottom: 30px;
  left: 0;
  width: 100%;

  background-color: white;
  //transform: translate(0px, calc(100% - 30px));
  //position: absolute;

  //iPhone 14 pro max
  @media only screen and (device-width: 430px) and (device-height: 932px) and (-webkit-device-pixel-ratio: 3) {
    padding-bottom: 20px;
  }

  //iPhone 14 plus
  @media only screen and (device-width: 428px) and (device-height: 926px) and (-webkit-device-pixel-ratio: 3) {
    padding-bottom: 20px;
  }

  //iPhone XR
  @media only screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 2) {
    padding-bottom: 20px;
  }

  //iPhone 14 pro
  @media only screen and (device-width: 393px) and (device-height: 852px) and (-webkit-device-pixel-ratio: 3) {
    padding-bottom: 20px;
  }

  //iPhone 14
  @media only screen and (device-width: 394px) and (device-height: 844px) and (-webkit-device-pixel-ratio: 3) {
    padding-bottom: 20px;
  }
  
  //iPhone X
  @media only screen and (device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3) {
    padding-bottom: 20px;
  }

  ul,
  ol {
    list-style-type: none;
    padding-left: 0;
  }

  ul {
    display: flex;
    margin: auto;
    max-width: 400px;

    @include media-breakpoint-up(lg) {
      //height: 100px;
      //max-width: 650px;
      max-width: 800px;
    }
  }

  li {
    flex: 1 1 20%;
    text-align: center;
  }
}


.bottomMenu__li {
  transition: all 0.5s ease-out;

  &:hover,
  &:focus,
  &.selected {
    background-color: rgba(#000, 0.1);
  }
}

.bottomMenu__link {

  display: inline-block;
  //color: white;
  text-align: center;
  padding: 4px 5px;
  cursor: pointer;
  transition: all 0.3s ease-out;
  opacity: 0.7;
  vertical-align: middle;
  position: relative;

  //padding-bottom: 10px;
  //font-family: $family-header;

  .div {
    position: relative;
    z-index: 100;
  }

  .icon {
    width: 32px;
    height: 32px;
    //@include bg-img();
    margin: auto;
    //transition: all 0.3s ease-out;
  }

  .div {
    font-size: 12px;
    letter-spacing: 0.5px;
    line-height: 1;
    margin-top: 0.3rem;
    font-weight: 700;

    &.bottomMenu__div__2 {
      white-space: nowrap;
      color: #000;
      //@include media-breakpoint-up(lg) {
        //margin-top: 0px;
        //position: absolute;
        //right: 110%;
        //top: 50%;
        //transform: translateY(-50%);
      //}
    }

  }

  &:hover,
  &:focus,
  &.selected {
    //transform: translate(0, -2px);
    color: white;
    text-decoration: none;
    opacity: 1;
  }

  @include media-breakpoint-up(lg) {
    padding: 15px 5px;

    .icon {
      width: 40px;
      height: 40px;
    }

    .div {
      font-size: 13px;
      letter-spacing: 0.5px;
    }
  }

  .bottomMenu__link__container{
    display: flex;
    align-items: center;
    flex-direction: column;
    @include media-breakpoint-up(lg){
      //flex-direction: row;
    }
    .icon.div {
      order: 1;
      margin-top: 0px;
    }
    .bottomMenu__div__2.div {
      order: 1;
      margin-right: unset;
      margin-top: 0.3rem;
      // @include media-breakpoint-up(lg){
      //   //order: 0;
      //   //margin-right: 0.5rem;
      //   //margin-top: 0px;
      // }
    }
  }
}

.icon {
  img {
    width: 100%;
    height: 100%;
  }

  .normal {
    display: block;
  }

  .selected {
    display: none;
  }
}

.bottomMenu__link:hover .icon,
.bottomMenu__link.selected .icon {
  .normal {
    display: none;
  }

  .selected {
    display: block;
  }
}

.bottomMenu__stripe{
  width: 100%;
  //background-color: red;
  height: 30px;
  position: fixed;
  bottom: 0px;
  z-index: 2;
  .bottomMenu__stripe__inner{
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url('/assets/theme-01.png?v=1');
    // img{
    //   width: 100%;
    // }
  }
}

.body-content.theme-color-theme1 .bottomMenu__stripe__inner{
  background-image: url('/assets/theme-01.png?v=1');
}

.body-content.theme-color-theme2 .bottomMenu__stripe__inner{
  background-image: url('/assets/theme-02.png?v=1');
}

.body-content.theme-color-theme3 .bottomMenu__stripe__inner{
  background-image: url('/assets/theme-03.png?v=1');
}

.body-content.theme-color-theme4 .bottomMenu__stripe__inner{
  background-image: url('/assets/theme-04.png?v=1');
}

.body-content.theme-color-theme5 .bottomMenu__stripe__inner{
  background-image: url('/assets/theme-05.png?v=1');
}

.body-content.theme-color-theme6 .bottomMenu__stripe__inner{
  background-image: url('/assets/theme-06.png?v=1');
}

//https://tympanus.net/Development/IconHoverEffects/#set-8