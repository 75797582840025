.bottomPanel {
  padding-bottom: 7rem;
  padding-top: 0.7rem;
  text-align: center;

  background: black;
}

.go-top {
  margin: 2rem 0;
}