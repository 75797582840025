.pageList {
}
.pageListItem {
  border-bottom: solid 1px #ddd;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  &__inner {
    margin: 0.5rem;
    text-align: left;
  }

  &__title {
    font-size: 1.8rem;
    font-family: $family-header;
    line-height: 1;
  }

  &__highlight {
    font-size: 0.8rem;
  }
}