%bannerSize--small {
  height: 220px;

  @include media-breakpoint-up(sm) { // 576px and up
    height: 260px;
  }
  @include media-breakpoint-up(md) { // 768px and
    height: 330px;
  }
  @include media-breakpoint-up(lg) { // 992px and up
    height: 400px;
  }
  @include media-breakpoint-up(xl) { // 1200px and up
    height: 450px;
  }
  @media (min-width: 1601px) {
    height: 530px;
  }
  @media (min-width: 1901px) {
    height: 600px;
  }
  @media (min-width: 2201px) {
    height: 700px;
  }
}