@import './file/fileLink';
@import './search';
@import './blog/blogList';
@import './blog/blogView';

// .multiColumnPanel .htmlPanel {
//   padding: 0 1em;
// }

.slick-slide.slick-current {
  z-index: 2;
}

.multiColumnPanel .col-12 {
  margin-bottom: 15px;

  &:last-of-type {
    margin-bottom: 0;
  }

  @media (min-width: 576px) {
    margin-bottom: 0;
  }
}